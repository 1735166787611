<template>
  <div class="sessions">
    <SessionInfo
      v-for="item in data"
      :key="item"
      :info="item"
      class="history-item"
    >
      <DateFormatter :format="dateTimeFormat" :date="item.updatedAt" />
    </SessionInfo>
    <table v-show="!data.length" style="width: 100%;">
      <NoData :colspan="1">
        <template #title>
          This admin does not have any login history yet
        </template>
      </NoData>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import NoData from '@/components/MainTable/NoData.vue';

import { useProfile } from '@/composables/useProfile';

import SessionInfo from './SessionInfo.vue';

export default defineComponent({
  components: {
    SessionInfo,
    NoData,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { dateTimeFormat } = useProfile();

    return { dateTimeFormat };
  },
});
</script>

<style scoped lang="scss">
.history-item {
  border-bottom: 1px solid var(--color-black-01);
  border-radius: 0;
  padding: 16px 40px;
}
.sessions {
  max-height: 500px;
  overflow: overlay;
}
</style>
