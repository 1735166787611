<template>
  <div style="padding: 30px 40px;">
    <div class="d-flex justify-content-between align-items-end" style="margin-bottom: 10px;">
      <AppText size="15px" class="font-medium">
        Email address
      </AppText>
      <AppText size="12px" color="#808080">
        Last changed:
        <DateFormatter v-if="lastChanged" :date="lastChanged" :format="dateFormat" />
        <slot v-else>
          Never
        </slot>
      </AppText>
    </div>
    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onPassedValidation"
    >
      <template #default="{ errors, action }">
        <div class="d-flex" style="margin-bottom: 40px;">
          <FInput
            v-model="requestFields.email"
            class="email-input"
            size="small"
            :disabled="isEmailUpdating"
            :validation-error="errors.email"
          />
          <FButton size="small" class="email-button" @click="action">
            Save
          </FButton>
        </div>
      </template>
    </ActionForm>

    <div style="min-height: 146px;">
      <AppText
        size="15px"
        class="font-medium"
        variant="div"
        style="margin-bottom: 10px;"
      >
        Active session
      </AppText>
      <Transition name="show">
        <div v-if="!isLoading" class="w-100 d-flex">
          <TransitionGroup name="fade">
            <template v-if="info.activeSession">
              <div class="w-100 d-flex flex-column">
                <SessionInfo :info="info.activeSession" :is-loading="onSessionKilling" @button-click="onKillSession" />
              </div>
            </template>
            <div v-else class="w-100 no-data">
              <AppText color="var(--color-939393)">
                None
              </AppText>
            </div>
          </TransitionGroup>
        </div>
      </Transition>
    </div>

    <Transition name="show">
      <div v-if="!isLoading">
        <FButton
          v-if="!info.activeSession && !isSuspended"
          type="plain"
          class="suspend-button"
          :loading="isAccountStatusChanging"
          @click="onSuspend"
        >
          <AppIcon name="power" size="20px" />
          Suspend account
        </FButton>

        <template v-else>
          <FButton
            v-if="isSuspended"
            type="plain"
            class="unsuspend-button"
            :loading="isAccountStatusChanging"
            @click="onUnsuspend"
          >
            <AppIcon name="power" size="20px" />
            Unsuspend account
          </FButton>

          <FButton
            v-else
            type="plain"
            class="kill-session-button"
            :loading="isAccountStatusChanging"
            @click="onSuspend"
          >
            <AppIcon name="minus-circle" size="20px" />
            <div class="d-flex flex-column align-items-start font-regular">
              Kill Session and
              <br>
              <span class="font-medium">Suspend account</span>
            </div>
          </FButton>
        </template>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, toRef,
} from 'vue';

import { ActionForm, emailRule, fieldValidation } from '@/validation';

import SessionInfo from './SessionInfo.vue';
import {
  changeAdminEmail,
  killSession,
  suspendAccount,
  unsuspendAccount,
} from './api';

export default defineComponent({
  components: {
    SessionInfo,
    ActionForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
    dateFormat: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
    } = fieldValidation({ fieldName: 'email', rule: emailRule });

    requestFields.email = toRef(props, 'item').value?.email;

    const lastChanged = computed(() => props.info?.emailLastUpdatedAt);

    const isEmailUpdating = ref(false);
    const onPassedValidation = async () => {
      isEmailUpdating.value = true;
      await changeAdminEmail(props.item.id, {
        email: requestFields.email,
      });
      emit('success');

      isEmailUpdating.value = false;
    };

    const isSuspended = computed(() => {
      const status = props.info?.status || props.item?.status;
      return status === 'SUSPENDED';
    });

    const isAccountStatusChanging = ref(false);
    const onSuspend = async () => {
      isAccountStatusChanging.value = true;
      await suspendAccount(props.item.id);
      emit('success');
      isAccountStatusChanging.value = false;
    };

    const onUnsuspend = async () => {
      isAccountStatusChanging.value = true;
      await unsuspendAccount(props.item.id);
      emit('success');
      isAccountStatusChanging.value = false;
    };

    const onSessionKilling = ref(false);
    const onKillSession = async () => {
      onSessionKilling.value = true;
      await killSession(props.item.id);
      emit('success');
      onSessionKilling.value = false;
    };

    return {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,

      lastChanged,
      onPassedValidation,
      isEmailUpdating,
      isSuspended,

      onSuspend,
      onUnsuspend,
      isAccountStatusChanging,

      onKillSession,
      onSessionKilling,
    };
  },
});
</script>

<style scoped lang="scss">
.email-input {
  :deep(.el-input__wrapper) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: unset !important;
  }
}

.email-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.unsuspend-button {
  background-color: var(--color-F7F7F7);
  border: none;
  @include transition-base('background-color');

  &:focus {
    background-color: var(--color-F7F7F7);
    border: none;
  }

  &:hover {
    background-color: var(--color-green-01) !important;

    :deep(span) {
      color: var(--color-green);
    }

    :deep(.icon) {
      fill: var(--color-green);
    }
  }
}

.kill-session-button {
  height: 60px;
  @include transition-base('background-color');

  &:hover {
    background-color: var(--color-error-01) !important;

    :deep(span) {
      color: var(--color-error);
    }

    :deep(.icon) {
      fill: var(--color-error);
    }
  }
}

.no-data {
  height: 77px;
  border: 1px solid var(--color-black-01);
  border-radius: 8px;

  @include flex-center;
}
</style>
