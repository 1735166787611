<template>
  <div class="session-info" :class="{ 'on-waiting': isInitializated }">
    <div>
      <AppText
        size="14px"
        class="font-medium"
        variant="div"
        mb="8px"
      >
        {{ title }}
      </AppText>
      <div class="d-flex align-items-center">
        <CountryFlag
          v-if="info?.countryCode"
          :value="info?.countryCode"
          size="18px"
          style="margin-right: 8px; margin-top: 2px;"
        />
        <AppText color="var(--color-939393)">
          {{ cityName }}
        </AppText>
        <AppText color="var(--color-D9D9D9)" pl="20px" pr="20px">
          |
        </AppText>
        <DeviceInfo :item="info" />
      </div>
    </div>

    <FSpacer />

    <slot>
      <FButton
        type="text"
        size="x-small"
        no-margin
        :loading="isLoading"
        :disabled="isInitializated"
        @click="$emit('button-click')"
      >
        Kill session
      </FButton>
    </slot>
  </div>
</template>

<script>
import 'flag-icons/css/flag-icons.min.css';
import {
  computed, defineComponent, ref, watch,
} from 'vue';

import CountryFlag from '@/components/Icon/CountryFlag.vue';
import DeviceInfo from '@/components/DeviceInfo.vue';

export default defineComponent({
  components: {
    DeviceInfo,
    CountryFlag,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['button-click'],
  setup(props) {
    const title = computed(() => props.info?.ip || 'Unknown IP address');
    const device = computed(() => props.info && `${props.info.deviceName} on ${props.info.deviceOs}`);

    const cityName = computed(() => (props.info?.city && `${props.info.city}, ${props.info.country}`) || 'Unknown location');

    const isInitializated = ref(true);

    watch(computed(() => props.info?.ip), () => {
      if (props.info?.ip || props.info?.createdAt || props.info?.deviceName) {
        isInitializated.value = false;
      }
    }, { immediate: true });

    return {
      title,
      cityName,
      device,

      isInitializated,
    };
  },
});
</script>

<style scoped lang="scss">
.session-info {
  border: 1px solid var(--color-black-01);
  border-radius: 8px;

  padding: 14px 20px;
  height: 77px;

  display: flex;
  align-items: center;

  opacity: 1;
  @include transition-base(opacity);

  &.on-waiting {
    opacity: 0;
  }
}

:deep(.el-button--text:hover) {
  background: var(--color-error) !important;
  color: var(--color-white);
}

:deep(.el-button.is-disabled) {
  border: 1px solid var(--color-black-005)
}
</style>
