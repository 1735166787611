import { useFetch } from '@/api';

export const getAdminInfo = async (adminId) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `/admin/${adminId}`,
    params: { isFreshResponse: true },
  });

  return { isSuccess, data };
};

export const getAdminHistory = async (adminId) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: `/admin/login-history/${adminId}`,
    params: { isFreshResponse: true },
  });

  return { isSuccess, data };
};

export const changeAdminEmail = async (adminId, params) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'patch',
    url: `/admin/email/${adminId}`,
    params,
  });

  if (isSuccess) {
    successHandler('Email has been successfully updated');
  }

  return { isSuccess, data };
};

export const suspendAccount = async (adminId) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'post',
    url: `/admin/suspend/${adminId}`,
  });

  if (isSuccess) {
    successHandler('Account has been successfully suspended');
  }

  return { isSuccess, data };
};

export const unsuspendAccount = async (adminId) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'post',
    url: `/admin/unsuspend/${adminId}`,
  });

  if (isSuccess) {
    successHandler('Account has been successfully unsuspended');
  }

  return { isSuccess, data };
};

export const killSession = async (adminId) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'delete',
    url: `/admin/device/${adminId}`,
  });

  if (isSuccess) {
    successHandler('Session has been killed');
  }

  return { isSuccess, data };
};
