<template>
  <div class="d-flex item-header">
    <AppAvatar size="40px" style="margin-right: 20px;" :src="info?.profilePicture?.crop" />
    <div>
      <TooltipCopy is-icon-hidden>
        <AppText size="18px" class="font-medium" variant="div">
          {{ name }}
        </AppText>
      </TooltipCopy>
      <div class="d-flex align-items-center mt-2">
        <slot />
        <DotSeparator color="#ccc" margin="0 12px" />
        <AppText
          class="font-medium status"
          :style="{
            '--color': color,
          }"
          :line-height="1"
        >
          {{ t(STATUS[info.status || item?.status]) }}
        </AppText>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import DotSeparator from '@/components/DotSeparator.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { COLORS, STATUS } from '@/common/status';
import colors from '@/styles/colors';

export default defineComponent({
  components: {
    AppAvatar,
    DotSeparator,
    TooltipCopy,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const type = computed(() => COLORS[props.info?.status?.toUpperCase() || props.item?.status?.toUpperCase()]);
    const color = computed(() => {
      if (props.item?.status === 'DELETED') {
        return '#939393';
      }

      return colors[`t${type.value}`];
    });

    const name = computed(() => props.item?.companyName || props.item?.username);

    return {
      name,
      color,
      STATUS,
    };
  },
});
</script>

<style scoped lang="scss">
.item-header {
  margin: 30px 40px 20px;
}

.status {
  color: var(--color);
}
</style>
