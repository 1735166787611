<template>
  <div class="d-flex align-items-center" style="height: 16px;">
    <AppIcon
      v-if="deviceIcon"
      :key="deviceIcon"
      :name="deviceIcon"
      is-img-tag
      size="14px"
      style="margin-right: 6px;"
      opacity="0.4"
    />
    <AppText color="#939393">
      {{ device }}
    </AppText>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const device = computed(() => props.item && `${props.item.deviceName} on ${props.item.deviceOs}`);

    const deviceIcon = computed(() => {
      if (['smartphone', 'phone', 'tablet', 'phablet', 'feature phone'].includes(props.item?.deviceType)) {
        return 'device-phone';
      }
      if (['desktop'].includes(props.item?.deviceType)) {
        return 'device-desktop';
      }
      return null;
    });

    return {
      device,
      deviceIcon,
    };
  },
});
</script>
