import { TABLE_TYPES, statusCellOptions } from '@/common/tableTypes';

export const columns = [
  {
    name: 'username',
    isShow: true,
    isPermanent: true,
  },
  {
    name: 'email',
    isShow: true,
    width: 350,
    isPermanent: true,
  },
  {
    name: 'createdAt',
    type: TABLE_TYPES.DATE_TIME,
    isShow: true,
    isForcedShow: true, // this option make column visible in case when column was hidden, but developer made this column permanent
    isPermanent: true,
  },
  {
    name: 'lastAccessDate',
    type: TABLE_TYPES.DATE_TIME,
    isShow: true,
    isPermanent: true,
  },
  {
    ...statusCellOptions,
  },
];

export const statuses = [
  { text: 'All', value: 'ALL' },
  { text: 'Active', value: 'ACTIVE' },
  { text: 'Suspended', value: 'SUSPENDED' },
];
