<template>
  <div>
    <ModalHeader :item="item" :info="selectedAdmin">
      <AppText color="var(--color-939393)">
        Registered <DateFormatter :format="dateTimeFormat" :date="item.createdAt" />
      </AppText>
    </ModalHeader>
    <TabsContainer
      v-model="currentTab"
      :options="tabs"
      :other-tab="{
        name: 'history',
        value: historyInfo.length,
      }"
      :is-loading="isHistoryLoading"
    >
      <div
        style="min-height: 380px;"
        :class="{
          'bottom-fade-in': currentTab === tabs[1].value,
        }"
      >
        <AdminDetails
          v-if="currentTab === tabs[0].value"
          :item="item"
          :info="selectedAdmin"
          :date-format="dateTimeFormat"
          :is-loading="isInfoLoading"
          @success="onGetAdmin"
        />
        <AdminHistory
          v-if="currentTab === tabs[1].value"
          :data="historyInfo"
          :date-format="dateTimeFormat"
        />
      </div>
    </TabsContainer>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';

import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import ModalHeader from '@/components/Containers/ModalHeader.vue';
import TabsContainer from '@/components/Containers/TabsContainer.vue';

import { useProfile } from '@/composables/useProfile';

import AdminDetails from './AdminDetails.vue';
import AdminHistory from './AdminHistory.vue';
import { getAdminHistory, getAdminInfo } from './api';

export default defineComponent({
  components: {
    TabsContainer,
    DateFormatter,
    ModalHeader,

    AdminDetails,
    AdminHistory,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const selectedAdmin = ref({});

    const tabs = [
      { text: 'Details', value: 'details' },
      { text: 'Login History', value: 'history' },
    ];

    const currentTab = ref(tabs[0].value);

    const isInfoLoading = ref(false);
    const isHistoryLoading = ref(false);
    const historyInfo = ref([]);

    const adminId = computed(() => props.item.id);
    watch(computed(() => props.isOpen), async () => {
      if (props.isOpen) {
        isInfoLoading.value = true;
        isHistoryLoading.value = true;

        getAdminInfo(adminId.value).then(({ data, isSuccess }) => {
          selectedAdmin.value = isSuccess ? data : {};
          isInfoLoading.value = false;
        });

        getAdminHistory(adminId.value).then(({ data, isSuccess }) => {
          historyInfo.value = isSuccess ? data : {};
          isHistoryLoading.value = false;
        });
      }
    }, { immediate: true });

    const onGetAdmin = () => {
      isInfoLoading.value = true;
      emit('success', { shouldCloseModal: false });

      getAdminInfo(adminId.value).then(({ data, isSuccess }) => {
        selectedAdmin.value = isSuccess ? data : {};
        isInfoLoading.value = false;
      });
    };

    const { dateTimeFormat } = useProfile();

    return {
      currentTab,
      tabs,

      selectedAdmin,
      isInfoLoading,
      isHistoryLoading,

      dateTimeFormat,

      historyInfo,

      onGetAdmin,
    };
  },
});
</script>
