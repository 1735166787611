<template>
  <PageTable
    ref="staffPage"
    v-model:item-modal="isItemModalOpen"
    :columns="columns"
    :statuses="statuses"
    :module-name="moduleName"
    :api-path="apiPath"
    :event-name="eventName"

    :create-params="{
      title: 'Add Admin',
      isDescriptionVisible: false,
      hasHeaderDivider: false,
    }"

    :info-params="{
      isDescriptionVisible: false,
      hasBodyPaddings: false,
      hasHeader: false,
      width: '660px',
    }"

    button-add-text="Add admin"
  >
    <template #item="{ item }">
      <AdminItem :item="item" :is-open="isItemModalOpen" s @success="onCreateSuccess" />
    </template>

    <template #create>
      <AdminCreate @success="onCreateSuccess" />
    </template>
  </PageTable>
</template>

<script>
import { ref } from 'vue';

import PageTable from '@/components/Modules/PageTable';

import { MODULES_NAMES } from '@/common/modules';

import AdminCreate from './AdminCreate.vue';
import AdminItem from './AdminItem';
import { columns, statuses } from './data';

export default {
  name: 'AdminStaff',
  components: {
    PageTable,
    AdminCreate,
    AdminItem,
  },
  setup() {
    const moduleName = MODULES_NAMES.ADMIN_STAFF;
    const apiPath = '/admin';
    const eventName = 'update-staff-page';

    const staffPage = ref(null);
    const onCreateSuccess = () => {
      staffPage.value.getFreshData();
    };

    const isItemModalOpen = ref(false);

    return {
      moduleName,
      apiPath,
      eventName,

      columns,
      statuses,

      staffPage,
      isItemModalOpen,
      onCreateSuccess,
    };
  },
};
</script>
