import { ref } from 'vue';

import { useFetch } from '@/api';

export const adminApi = () => {
  const isLoading = ref(false);

  const inviteAdmin = async ({ username, email }) => {
    const { axiosWrapper, successHandler } = useFetch();
    isLoading.value = true;
    const { isSuccess, errorObject } = await axiosWrapper({
      type: 'post',
      url: '/admin/invitation/new',
      params: { username, email },
    });
    isLoading.value = false;

    if (isSuccess) {
      successHandler('Admin has been invited');
    }

    return { isSuccess, errorObject };
  };

  return {
    inviteAdmin,
    isLoading,
  };
};
