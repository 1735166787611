<template>
  <ActionForm
    :request-fields="requestFields"
    :validation-schema="validationSchema"
    :response-errors="fieldsErrors"
    class="add-admin-form"
    @validate="onValidate"
  >
    <template #default="{ errors, action }">
      <FInput
        v-model="requestFields.username"
        has-prefix
        placeholder="Username"
        :validation-error="errors.username"
        :maxlength="25"
      >
        <template #prefix>
          <AppIcon
            name="user"
            opacity="0.4"
            size="18px"
            is-img-tag
          />
        </template>
      </FInput>
      <FInput
        v-model="requestFields.email"
        has-prefix
        placeholder="Email Address "
        :validation-error="errors.email"
        class="email-input"
      >
        <template #prefix>
          <AppIcon
            name="mail"
            opacity="0.4"
            size="18px"
            is-img-tag
          />
        </template>
      </FInput>

      <AppAlert style="margin-top: 20px;">
        <AppIcon
          name="info-circle-medium"
          size="18px"
          :opacity="0.4"
          is-img-tag
          style="margin-right: 12px;"
        />

        <AppText color="var(--color-939393)">
          Email with a registration link will be sent immediately
        </AppText>
      </AppAlert>

      <FButton :loading="isLoading" @click="action">
        Send Invite
      </FButton>
    </template>
  </ActionForm>
</template>

<script>
import { defineComponent } from 'vue';

import AppAlert from '@/components/AppAlert.vue';

import { ActionForm, useValidation, yup } from '@/validation';
import { emailRule, adminNameRule } from '@/validation/rules';

import { adminApi } from './api';

export default defineComponent({
  components: {
    ActionForm,
    AppAlert,
  },
  emits: ['success'],
  setup(props, { emit }) {
    const { initState, fieldsErrors } = useValidation();

    const fields = {
      email: '',
      username: '',
    };

    const validationSchema = yup.object().shape({
      email: emailRule,
      username: adminNameRule,
    });

    const { requestFields } = initState(fields);

    const { inviteAdmin, isLoading } = adminApi();

    const onValidate = async () => {
      const { isSuccess, errorObject } = await inviteAdmin({
        username: requestFields.username,
        email: requestFields.email,
      });
      if (isSuccess) {
        emit('success');
      }
      if (!isSuccess) {
        fieldsErrors.value = errorObject.fields;
      }
    };

    return {
      validationSchema,
      requestFields,
      fieldsErrors,

      isLoading,
      onValidate,
    };
  },
});
</script>

<style scoped lang="scss">
.add-admin-form {
  .email-input {
    margin-top: 20px;
  }

  :deep(.el-button) {
    margin-top: 20px;
  }
}
</style>
